import { useMemo } from "react";

import type { Locale, Site } from "~/contexts";

import { getSiteForLocale } from "./utils/site-utils";

export interface CountryType
  extends Omit<ReturnType<typeof toCountryObject>, "isAvailable"> {
  isAvailable?: boolean;
}

export type CountryEntryType = Readonly<
  [
    name: string,
    countryCode: string,
    currencyCode: string,
    siteCountry: string,
    locale: string,
    icon: string,
    currencySymbol: string,
    isAvailable?: boolean,
  ]
>;
export const getCountryByCode = (code: string): CountryType | undefined => {
  const country = countries.find(country => country[1] === code);
  if (!country) {
    return undefined;
  }
  return toCountryObject(country);
};

export const getNotAvailableCountryByCode = (
  code: string,
): CountryType | undefined => {
  const country = notAvailableCountries.find(country => country[1] === code);
  if (!country) {
    return undefined;
  }
  return toCountryObject(country);
};

export const getCountryByLocale = (locale: string): CountryType | undefined => {
  const country = countries.find(country => country[4] === locale);
  if (!country) {
    return undefined;
  }
  return toCountryObject(country);
};

export const getLocaleByCountry = (
  countryCode: CountryType | string,
): [Locale | undefined, Site | undefined] => {
  const country =
    typeof countryCode === "string"
      ? getCountryByCode(countryCode)
      : countryCode;
  if (!country) {
    return [undefined, undefined];
  }
  const site = getSiteForLocale(country.locale);
  return [
    site.l10n.supportedLocales.find(
      locale => locale.alias === country.locale || locale.id === country.locale,
    ),
    site,
  ];
};
export const useCountry = (code: string): CountryType | undefined => {
  const country = useMemo(() => getCountryByCode(code), [code]);

  const notAvailableCountry = useMemo(
    () => getNotAvailableCountryByCode(code),
    [code],
  );

  if (country) {
    return country;
  }

  if (!notAvailableCountry) return undefined;

  return {
    ...notAvailableCountry,
    isAvailable: false,
  };
};

export const toCountryObject = (country: CountryEntryType) => {
  return {
    name: country[0],
    countryCode: country[1],
    currencyCode: country[2],
    siteCountry: country[3],
    locale: country[4],
    icon: country[5],
    currencySymbol: country[6],
    isAvailable: country[7],
  };
};

export const countries: Readonly<CountryEntryType[]> = [
  ["United Kingdom", "GB", "GBP", "GB", "default", "icon-united-kingdom", "£"],
  ["United States", "US", "USD", "US", "en-US", "icon-united-states", "$"],
] as const;

export const notAvailableCountries: Readonly<CountryEntryType[]> = [
  ["Albania", "AL", "ALL", "GB", "default", "icon-albania", "ALL"],
  ["Algeria", "DZ", "DZD", "GB", "default", "icon-algeria", "DA"],
  ["American Samoa", "AS", "USD", "GB", "default", "icon-american-samoa", "$"],
  ["Andorra", "AD", "EUR", "IE", "en-AU", "icon-andorra", "€"],
  ["Angola", "AO", "GBP", "GB", "default", "icon-angola", "£"],
  ["Anguilla", "AI", "XCD", "GB", "default", "icon-anguilla", "EC$"],
  [
    "Antigua and Barbuda",
    "AG",
    "XCD",

    "GB",
    "default",
    "icon-antigua-and-barbuda",
    "EC$",
  ],
  ["Argentina", "AR", "ARS", "GB", "default", "icon-argentina", "AR$"],
  ["Aruba", "AW", "AWG", "GB", "default", "icon-aruba", "Afl."],
  ["Australia", "AU", "AUD", "AU", "en-AU", "icon-australia", "AU$"],
  ["Austria", "AT", "EUR", "IE", "en-AU", "icon-austria", "€"],
  ["Bahamas", "BS", "BSD", "GB", "default", "icon-bahamas", "BS$"],
  ["Bahrain", "BH", "BHD", "GB", "default", "icon-bahrain", "BD"],
  ["Bangladesh", "BD", "BDT", "GB", "default", "icon-bangladesh", "Tk"],
  ["Barbados", "BB", "BBD", "GB", "default", "icon-barbados", "Bds$"],
  ["Belgium", "BE", "EUR", "IE", "en-AU", "icon-belgium", "€"],
  ["Belize", "BZ", "BZD", "GB", "default", "icon-belize", "BZ$"],
  ["Benin", "BJ", "XOF", "GB", "default", "icon-benin", "CFA"],
  ["Bermuda", "BM", "BMD", "GB", "default", "icon-bermuda", "BD$"],
  ["Bhutan", "BT", "BTN", "GB", "default", "icon-bhutan", "Nu."],
  ["Bolivia", "BO", "BOB", "GB", "default", "icon-bolivia", "Bs"],
  ["Bonaire", "BQ", "USD", "GB", "default", "icon-bonaire", "$"],
  [
    "Bosnia and Herzegovina",
    "BA",
    "BAM",

    "GB",
    "default",
    "icon-bosnia-herzegovina",
    "KM",
  ],
  ["Botswana", "BW", "BWP", "GB", "default", "icon-botswana", "BWP"],
  ["Brazil", "BR", "BRL", "GB", "default", "icon-brazil", "R$"],
  ["Brunei", "BN", "BND", "GB", "default", "icon-brunei", "BN$"],
  ["Bulgaria", "BG", "BGN", "GB", "default", "icon-bulgaria", "BGN"],
  ["Burkina Faso", "BF", "GBP", "GB", "default", "icon-burkina-faso", "£"],
  ["Burundi", "BI", "GBP", "GB", "default", "icon-burundi", "£"],
  ["Cambodia", "KH", "KHR", "GB", "default", "icon-cambodia", "KHR"],
  ["Cameroon", "CM", "GBP", "GB", "default", "icon-cameroon", "£"],
  ["Canada", "CA", "CAD", "US", "en-US", "icon-canada", "CA$"],
  ["Cape Verde", "CV", "CVE", "GB", "default", "icon-cape-verde", "CV$"],
  [
    "Cayman Islands",
    "KY",
    "KYD",

    "GB",
    "default",
    "icon-cayman-islands",
    "KY$",
  ],
  [
    "Central African Republic",
    "CF",
    "GBP",

    "GB",
    "default",
    "icon-central-african-republic",
    "£",
  ],
  ["Chad", "TD", "GBP", "GB", "default", "icon-chad", "£"],
  ["Chile", "CL", "CLP", "GB", "default", "icon-chile", "CL$"],
  ["China", "CN", "CNY", "GB", "default", "icon-china", "CN¥"],
  ["Colombia", "CO", "COP", "GB", "default", "icon-colombia", "COP"],
  ["Comoros", "KM", "KMF", "GB", "default", "icon-comoros", "CF"],
  ["Cook Islands", "CK", "NZD", "GB", "default", "icon-cook-islands", "NZ$"],
  ["Costa Rica", "CR", "CRC", "GB", "default", "icon-costa-rica", "₡"],
  ["Croatia", "HR", "EUR", "GB", "default", "icon-croatia", "€"],
  ["Cuba", "CU", "GBP", "GB", "default", "icon-cuba", "£"],
  ["Curacao", "CW", "ANG", "GB", "default", "icon-curacao", "NAf."],
  ["Cyprus", "CY", "EUR", "IE", "en-AU", "icon-cyprus", "€"],
  ["Czech Republic", "CZ", "CZK", "GB", "default", "icon-czech-republic", "Kč"],
  ["Denmark", "DK", "DKK", "GB", "default", "icon-denmark", "kr"],
  ["Djibouti", "DJ", "DJF", "GB", "default", "icon-djibouti", "Fdj"],
  ["Dominica", "DM", "XCD", "GB", "default", "icon-dominica", "EC$"],
  [
    "Dominican Republic",
    "DO",
    "DOP",
    "GB",
    "default",
    "icon-dominican-republic",
    "RD$",
  ],
  ["Ecuador", "EC", "USD", "GB", "default", "icon-ecuador", "$"],
  ["El Salvador", "SV", "USD", "GB", "default", "icon-el-salvador", "$"],
  [
    "Equatorial Guinea",
    "GQ",
    "GBP",

    "GB",
    "default",
    "icon-equatorial-guinea",
    "£",
  ],
  ["Estonia", "EE", "EUR", "IE", "en-AU", "icon-estonia", "€"],
  ["Ethiopia", "ET", "ETB", "GB", "default", "icon-ethiopia", "Br"],
  [
    "Falkland Islands",
    "FK",
    "FKP",

    "GB",
    "default",
    "icon-falkland-islands",
    "FK£",
  ],
  ["Faroe Islands", "FO", "DKK", "GB", "default", "icon-faroe-islands", "kr"],
  ["Fiji", "FJ", "FJD", "GB", "default", "icon-fiji", "FJ$"],
  ["Finland", "FI", "EUR", "IE", "en-AU", "icon-finland", "€"],
  ["France", "FR", "EUR", "IE", "en-AU", "icon-france", "€"],
  ["French Guiana", "GF", "EUR", "GB", "default", "icon-french-guiana", "€"],
  ["Gabon", "GA", "GBP", "GB", "default", "icon-gabon", "£"],
  ["Gambia", "GM", "GBP", "GB", "default", "icon-gambia", "£"],
  ["Georgia", "GE", "GEL", "GB", "default", "icon-georgia", "GEL"],
  ["Germany", "DE", "EUR", "IE", "en-AU", "icon-germany", "€"],
  ["Ghana", "GH", "GBP", "GB", "default", "icon-ghana", "£"],
  ["Gibraltar", "GI", "GBP", "GB", "default", "icon-gibraltar", "£"],
  ["Greece", "GR", "EUR", "IE", "en-AU", "icon-greece", "€"],
  ["Greenland", "GL", "DKK", "GB", "default", "icon-greenland", "kr"],
  ["Grenada", "GD", "XCD", "GB", "default", "icon-grenada", "EC$"],
  ["Guatemala", "GT", "GTQ", "GB", "default", "icon-guatemala", "GTQ"],
  ["Guernsey", "GG", "GBP", "GB", "default", "icon-guernsey", "£"],
  ["Guinea Bissau", "GW", "XOF", "GB", "default", "icon-guinea", "CFA"],
  ["Guyana", "GY", "GYD", "GB", "default", "icon-guyana", "GY$"],
  ["Honduras", "HN", "HNL", "GB", "default", "icon-honduras", "HNL"],
  ["Hong Kong", "HK", "HKD", "GB", "default", "icon-hong-kong", "HK$"],
  ["Hungary", "HU", "HUF", "GB", "default", "icon-hungary", "Ft"],
  ["Iceland", "IS", "ISK", "GB", "default", "icon-iceland", "kr"],
  ["India", "IN", "INR", "GB", "default", "icon-india", "₹"],
  ["Indonesia", "ID", "IDR", "GB", "default", "icon-indonesia", "Rp"],
  ["Ireland", "IE", "EUR", "IE", "en-EU", "icon-ireland", "€"],
  ["Isle of Man", "IM", "GBP", "GB", "default", "icon-isle-of-man", "£"],
  ["Israel", "IL", "ILS", "GB", "default", "icon-israel", "₪"],
  ["Italy", "IT", "EUR", "IE", "en-AU", "icon-italy", "€"],
  ["Jamaica", "JM", "JMD", "GB", "default", "icon-jamaica", "J$"],
  ["Japan", "JP", "JPY", "GB", "default", "icon-japan", "¥"],
  ["Jersey", "JE", "GBP", "GB", "default", "icon-jersey", "£"],
  ["Jordan", "JO", "JOD", "GB", "default", "icon-jordan", "JD"],
  ["Kazakhstan", "KZ", "KZT", "GB", "default", "icon-kazakhstan", "KZT"],
  ["Kenya", "KE", "KES", "GB", "default", "icon-kenya", "KSh"],
  ["Kiribati", "KI", "AUD", "GB", "default", "icon-kiribati", "AU$"],
  ["Kosovo", "XK", "GBP", "GB", "default", "icon-kosovo", "£"],
  ["Kuwait", "KW", "KWD", "GB", "default", "icon-kuwait", "KD"],
  ["Kyrgyzstan", "KG", "KGS", "GB", "default", "icon-kyrgyzstan", "KGS"],
  ["Laos", "LA", "LAK", "GB", "default", "icon-laos", "₭"],
  ["Latvia", "LV", "EUR", "IE", "en-AU", "icon-latvia", "€"],
  ["Lesotho", "LS", "LSL", "GB", "default", "icon-lesotho", "LSL"],
  ["Liechtenstein", "LI", "CHF", "GB", "default", "icon-liechtenstein", "CHF"],
  ["Lithuania", "LT", "EUR", "IE", "en-AU", "icon-lithuania", "€"],
  ["Luxembourg", "LU", "EUR", "IE", "en-AU", "icon-luxembourg", "€"],
  ["Macau SAR", "MO", "MOP", "GB", "default", "icon-macau-sar", "MOP$"],
  ["Madagascar", "MG", "MGA", "GB", "default", "icon-madagascar", "MGA"],
  ["Malawi", "MW", "MWK", "GB", "default", "icon-malawi", "MWK"],
  ["Malaysia", "MY", "MYR", "GB", "default", "icon-malaysia", "RM"],
  ["Maldives", "MV", "MVR", "GB", "default", "icon-maldives", "MVR"],
  ["Mali", "ML", "GBP", "GB", "default", "icon-mali", "£"],
  ["Malta", "MT", "EUR", "IE", "en-AU", "icon-malta", "€"],
  [
    "Marshall Islands",
    "MH",
    "USD",

    "GB",
    "default",
    "icon-marshall-islands",
    "$",
  ],
  ["Martinique", "MQ", "EUR", "GB", "default", "icon-martinique", "€"],
  ["Mauritania", "MR", "MRU", "GB", "default", "icon-mauritania", "£"],
  ["Mauritius", "MU", "MUR", "GB", "default", "icon-mauritius", "MURs"],
  ["Mayotte", "YT", "EUR", "IE", "en-AU", "icon-mayotte", "€"],
  ["Mexico", "MX", "MXN", "GB", "default", "icon-mexico", "Mex$"],
  ["Micronesia", "FM", "USD", "GB", "default", "icon-micronesia", "$"],
  ["Moldova", "MD", "MDL", "GB", "default", "icon-moldova", "MDL"],
  ["Monaco", "MC", "EUR", "IE", "en-AU", "icon-monaco", "€"],
  ["Mongolia", "MN", "MNT", "GB", "default", "icon-mongolia", "₮"],
  ["Montenegro", "ME", "EUR", "IE", "en-AU", "icon-montenegro", "€"],
  ["Montserrat", "MS", "XCD", "GB", "default", "icon-montserrat", "EC$"],
  ["Morocco", "MA", "MAD", "GB", "default", "icon-morocco", "MAD"],
  ["Mozambique", "MZ", "MZN", "GB", "default", "icon-mozambique", "MTn"],
  ["Namibia", "NA", "NAD", "GB", "default", "icon-namibia", "N$"],
  ["Nauru", "NR", "AUD", "GB", "default", "icon-nauru", "AU$"],
  ["Nepal", "NP", "NPR", "GB", "default", "icon-nepal", "NPRs"],
  [
    "Netherlands Antilles",
    "AN",
    "ANG",

    "GB",
    "default",
    "icon-netherlands",
    "NAf.",
  ],
  ["Netherlands", "NL", "EUR", "IE", "en-AU", "icon-netherlands", "€"],
  ["New Caledonia", "NC", "XPF", "GB", "default", "icon-new-caledonia", "CFPF"],
  ["New Zealand", "NZ", "NZD", "AU", "en-AU", "icon-new-zealand", "NZ$"],
  ["Nicaragua", "NI", "NIO", "GB", "default", "icon-nicaragua", "NIO"],
  ["Niger", "NE", "GBP", "GB", "default", "icon-niger", "£"],
  ["Nigeria", "NG", "NGN", "GB", "default", "icon-nigeria", "₦"],
  ["Niue", "NU", "NZD", "GB", "default", "icon-niue", "NZ$"],
  [
    "North Macedonia",
    "MK",
    "MKD",

    "GB",
    "default",
    "icon-north-macedonia",
    "MKD",
  ],
  [
    "Northern Mariana Islands",
    "MP",
    "USD",

    "GB",
    "default",
    "icon-northern-mariana-islands",
    "$",
  ],
  ["Norway", "NO", "NOK", "GB", "default", "icon-norway", "kr"],
  ["Oman", "OM", "OMR", "GB", "default", "icon-oman", "OMR"],
  ["Pakistan", "PK", "PKR", "GB", "default", "icon-pakistan", "Rs"],
  ["Palau", "PW", "USD", "GB", "default", "icon-palau", "$"],
  ["Panama", "PA", "USD", "GB", "default", "icon-panama", "$"],
  [
    "Papua New Guinea",
    "PG",
    "PGK",

    "GB",
    "default",
    "icon-papua-new-guinea",
    "PGK",
  ],
  ["Paraguay", "PY", "PYG", "GB", "default", "icon-paraguay", "₲"],
  ["Peru", "PE", "PEN", "GB", "default", "icon-peru", "S/."],
  ["Philippines", "PH", "PHP", "GB", "default", "icon-philippines", "₱"],
  ["Poland", "PL", "PLN", "GB", "default", "icon-poland", "zł"],
  ["Portugal", "PT", "EUR", "IE", "en-AU", "icon-portugal", "€"],
  ["Puerto Rico", "PR", "USD", "GB", "default", "icon-puerto-rico", "$"],
  ["Qatar", "QA", "QAR", "GB", "default", "icon-qatar", "QR"],
  ["Reunion", "RE", "EUR", "GB", "default", "icon-reunion", "€"],
  ["Romania", "RO", "RON", "GB", "default", "icon-romania", "RON"],
  // ['Russia', 'RU', 'RUB',  'GB', 'icon-russia', 'RUB'],
  ["Rwanda", "RW", "GBP", "GB", "default", "icon-rwanda", "£"],
  [
    "Saint Kitts and Nevis",
    "KN",
    "XCD",

    "GB",
    "default",
    "icon-saint-kitts-and-nevis",
    "EC$",
  ],
  ["Saint Lucia", "LC", "XCD", "GB", "default", "icon-saint-lucia", "EC$"],
  [
    "Saint Pierre and Miquelon",
    "PM",
    "GBP",

    "GB",
    "default",
    "icon-saint-pierre-and-miquelon",
    "£",
  ],
  [
    "Saint Vincent and Grenadines",
    "VC",
    "GBP",

    "GB",
    "default",
    "icon-saint-vincent-and-grenadines",
    "£",
  ],
  ["Samoa", "WS", "WST", "GB", "default", "icon-samoa", "WS$"],
  ["San Marino", "SM", "EUR", "IE", "en-AU", "icon-san-marino", "€"],
  [
    "Sao Tome and Principe",
    "ST",
    "STD",

    "GB",
    "default",
    "icon-sao-tome-and-principe",
    "Db",
  ],
  ["Saudi Arabia", "SA", "SAR", "GB", "default", "icon-saudi-arabia", "SAR"],
  ["Senegal", "SN", "XOF", "GB", "default", "icon-senegal", "CFA"],
  ["Serbia", "RS", "RSD", "GB", "default", "icon-serbia", "din."],
  ["Seychelles", "SC", "SCR", "GB", "default", "icon-seychelles", "SRe"],
  ["Singapore", "SG", "SGD", "GB", "default", "icon-singapore", "S$"],
  ["Sint Maarten", "SX", "GBP", "GB", "default", "icon-sint-maarten", "£"],
  ["Slovakia", "SK", "EUR", "IE", "en-AU", "icon-slovakia", "€"],
  ["Slovenia", "SI", "EUR", "IE", "en-AU", "icon-slovenia", "€"],
  [
    "Solomon Islands",
    "SB",
    "SBD",

    "GB",
    "default",
    "icon-solomon-islands",
    "SI$",
  ],
  ["South Africa", "ZA", "ZAR", "GB", "default", "icon-south-africa", "R"],
  ["South Korea", "KR", "KRW", "GB", "default", "icon-south-korea", "₩"],
  ["Spain", "ES", "EUR", "IE", "en-AU", "icon-spain", "€"],
  ["Sri Lanka", "LK", "LKR", "GB", "default", "icon-sri-lanka", "SLRs"],
  ["Sudan", "SD", "SDG", "GB", "default", "icon-sudan", "SDG"],
  ["Suriname", "SR", "SRD", "GB", "default", "icon-suriname", "SR$"],
  ["Swaziland", "SZ", "SZL", "GB", "default", "icon-swaziland", "SZL"],
  ["Sweden", "SE", "SEK", "GB", "default", "icon-sweden", "kr"],
  ["Switzerland", "CH", "CHF", "GB", "default", "icon-switzerland", "CHF"],
  ["Taiwan Area", "TW", "TWD", "GB", "default", "icon-taiwan-area", "NT$"],
  ["Tajikistan", "TJ", "GBP", "GB", "default", "icon-tajikistan", "£"],
  ["Tanzania", "TZ", "TZS", "GB", "default", "icon-tanzania", "TSh"],
  ["Thailand", "TH", "THB", "GB", "default", "icon-thailand", "฿"],
  ["Togo", "TG", "GBP", "GB", "default", "icon-togo", "£"],
  ["Tonga", "TO", "TOP", "GB", "default", "icon-tonga", "T$"],
  [
    "Trinidad and Tobago",
    "TT",
    "TTD",

    "GB",
    "default",
    "icon-trinidad-and-tobago",
    "TT$",
  ],
  ["Turkey", "TR", "TRY", "GB", "default", "icon-turkey", "TL"],
  ["Turkmenistan", "TM", "TMT", "GB", "default", "icon-turkmenistan", "TMT"],
  [
    "Turks and Caicos Islands",
    "TC",
    "USD",

    "GB",
    "default",
    "icon-turks-and-caicos-islands",
    "$",
  ],
  ["Tuvalu", "TV", "AUD", "GB", "default", "icon-tuvalu", "AU$"],
  ["Uganda", "UG", "GBP", "GB", "default", "icon-uganda", "£"],
  // ['Ukraine', 'UA', 'UAH',  'GB', 'icon-ukraine', 'UAH'],
  [
    "United Arab Emirates",
    "AE",
    "AED",

    "GB",
    "default",
    "icon-united-arab-emirates",
    "AED",
  ],
  ["Uruguay", "UY", "UYU", "GB", "default", "icon-uruguay", "$U"],
  ["Uzbekistan", "UZ", "UZS", "GB", "default", "icon-uzbekistan", "UZS"],
  ["Vanuatu", "VU", "VUV", "GB", "default", "icon-vanuatu", "VT"],
  ["Vatican City", "VA", "EUR", "IE", "en-AU", "icon-vatican-city", "€"],
  ["Venezuela", "VE", "GBP", "GB", "default", "icon-venezuela", "£"],
  ["Vietnam", "VN", "VND", "GB", "default", "icon-vietnam", "₫"],
  [
    "Virgin Islands, British",
    "VG",
    "USD",

    "GB",
    "default",
    "icon-virgin-islands",
    "$",
  ],
  [
    "Wallis and Futuna Islands",
    "WF",
    "XPF",

    "GB",
    "default",
    "icon-wallis-and-futuna-islands",
    "CFPF",
  ],
  ["Yemen", "YE", "GBP", "GB", "default", "icon-yemen", "£"],
  ["Zambia", "ZM", "ZMW", "GB", "default", "icon-zambia", "ZMW"],
] as const;
