"use client";

import * as React from "react";

import { ChevronDownIcon } from "@radix-ui/react-icons";

import { Button } from "~/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { cn } from "~/lib/ui";

import { ScrollArea } from "./scrollarea";

export function Combobox({
  suggestions,
  onSelect,
  defaultValue,
  id,
  className,
  scrollAreaClassName,
  children,
  trigger,
}: {
  id?: string;
  suggestions: Array<{ label: string; value: string }>;
  onSelect?: (a: string) => void;
  defaultValue?: string;
  className?: string;
  scrollAreaClassName?: string;
  trigger?: (props: {
    value: string;
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
  children?: (props: {
    value: string;
    setValue: (value: string) => void;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue || "");
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {trigger ? (
          trigger({ value, setOpen, isOpen: open })
        ) : (
          <Button
            id={id}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "justify-between rounded border border-stone-200 bg-white px-3 py-1 text-base font-normal text-contrast-black shadow-sm",
              className,
            )}
          >
            {value
              ? suggestions.find(suggestion => suggestion.value === value)
                  ?.label
              : "Select option..."}
            <ChevronDownIcon />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start">
        <Command>
          <CommandInput placeholder="Search option..." />
          <CommandEmpty>No option found.</CommandEmpty>
          {children &&
            children({
              value,
              setValue: _value => {
                setValue(value === _value ? "" : _value);
                setOpen(false);
                if (onSelect) onSelect(_value);
              },
            })}
          <ScrollArea className={cn(scrollAreaClassName)}>
            <CommandGroup>
              {suggestions.map((suggestion, index) => (
                <CommandItem
                  key={index}
                  onSelect={() => {
                    setValue(
                      suggestion.value === value ? "" : suggestion.value,
                    );
                    setOpen(false);
                    if (onSelect) onSelect(suggestion.value);
                  }}
                >
                  {suggestion.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
